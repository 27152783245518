import { Controller } from 'stimulus'; 


function enlargeBlock(firstId, secondId) {
  if ($('#'+secondId).css('display') == 'none') {
    $('#'+secondId).show();
    $('#'+firstId).removeClass('large-12').addClass('large-6');
    $('#'+firstId).removeClass('medium-12').addClass('medium-6');                                                                     
    $('.slick-slider').slick('reinit');
    //$(window).trigger('resize');
    $('.slick-slider .block').css('height', '226px');
    $('.slick-slider .block .orbit-image').css('height', '226px');
    $('.hotel-slider-map #MapCanvas').css('height', '226px');
  } else {
    $('#'+secondId).hide();
    $('#'+firstId).removeClass('large-6').addClass('large-12');
    $('#'+firstId).removeClass('medium-6').addClass('medium-12');                                                                    
    $('.slick-slider').slick('reinit');
    //$(window).trigger('resize');
    $('.slick-slider .block').css('height', '400px');
    $('.slick-slider .block .orbit-image').css('height', '400px');
    $('.hotel-slider-map #MapCanvas').css('height', '400px');
  }
}

export default class extends Controller {

  connect() {
    console.log("CONNECT enlarge_controller")

    $(this.element).on('click', '#slick-slider-block .enlarge-icon', function() {
      enlargeBlock('slick-slider-block', 'map-block');
    });

    $(this.element).on('click', '#map-block .enlarge-icon', function() {
      enlargeBlock('map-block', 'slick-slider-block');
    });
  }
}
