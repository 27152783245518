import { Controller } from 'stimulus';
import { force_autocomplete_search } from './../../src/javascripts/autocomplete_utils.js';
// Jquery based autocomplete with jsonp
// Author: Stefan Amann
// Date: 26.03.2012

String.prototype.capitalize = function(){
  return this.replace( /(^|\s)([a-z])/g , function(m,p1,p2){
    return p1+p2.toUpperCase();
  });
};

function init_flight_autocomplete(autocomplete_id, agentc, language) {
  // var destfinder_url = "http://193.135.59.163:6100/flightx-engine/";
  var destfinder_url = "/flight/destination";
  $(document).trigger('ready');
  $(autocomplete_id).autocomplete({
    source: function(request, response) {
      Rails.ajax({
        url: destfinder_url + "?s=" + request.term + "&language=" + language + "&agentc=" + agentc,
        type: "get",
        dataType: "HTML",
        data: "",
        /* jsonp */
        success: function( data ) {
	    if (Array.isArray(data)) {
	        var rows = data;
    	} else {
    	    var rows = new Array(data);
    	}
        response( $.map(rows, function( item ) {
            // console.log(data);
            return {
              label: item.city + ' (' +item.country.capitalize() +')',
              value: item.city,
              tlc: item.tlc
            }
          }));
        },
        error: function(jqXHR, textStatus, errorThrown) {
          alert("received error: " + textStatus);
        }
      })
    },
    autoFocus: true,
    // keypress: function(event) {},
    select: function(event, ui) {
      var $this = $(this);

      $this.on("blur", null);
      $this.on("change", null);

      var TABKEY = 9;
      //this.value = ui.item.value;
	  $this.val(ui.item.value);
	  $this.attr('data-selected', ui.item.value);

      if (event.keyCode == TABKEY) {
        event.preventDefault();
      }

      $this.text(ui.item.value);
      $this.attr('data-tlc', ui.item.tlc);
      try {$this.data().autocomplete.previous = null;} catch (e){}
      try {
        var tabindex = parseInt($this.data('tabstop'));
        // var next_element = $("input[tabstop]").eq(tabindex);
        var next_element = $("input[data-tabstop='" +(tabindex+1) +"']")
        // next_element.focus();
        // setTimeout(function(){next_element.focus()}); // - deactivate because of deeplinks
        return false;
      }
      catch (e){}
    },
    focus: function (event, ui) {
      var ARROWUP = 38;
      var ARROWDOWN = 40;
      var val = $(this).val();

      if (event.keyCode == ARROWUP || event.keyCode == ARROWDOWN) {
        event.preventDefault();
        $(".ui-menu-item div").css("font-weight", "normal");
        $("div:contains('" + ui.item.label + "'):first").css("font-weight", "bold");
      }

      return false;
    },

    minLength: 2,
    delay: 0,

  });
}

export default class extends Controller {

  connect() {
    console.log("CONNECT flight/autocomplete_controller")

    var element         = this.element
    var autocomplete_id = element.dataset.autocompleteId
    var client          = element.dataset.client
    var base_language   = element.dataset.baseLanguage
    // system and filter are not used

    init_flight_autocomplete(autocomplete_id, client, base_language)
    force_autocomplete_search()
  }



}
