import { Controller } from 'stimulus'; 
// todo: remove fieldName from js and html

function trigger_event(id, event_name, bubble, cancellable) {
  var event = document.createEvent('Event');
  event.initEvent(event_name, bubble, cancellable); //can bubble, and is cancellable
  document.getElementById(id).dispatchEvent(event);
}


export default class extends Controller {

  connect() {
    console.log("CONNECT minus_plus_controller")

    var button_minus = this.element.querySelector(".qtyminus")
    var button_plus  = this.element.querySelector(".qtyplus")
    var input_middle = this.element.querySelector("input[type=text]")

    // This button will increment the value
    $(button_plus).click(function(e){
      // Stop acting like a button
      e.preventDefault();
      // Get the field name
      var fieldName = $(this).attr('field');
      var maxVal = 4; // default - will be overridden later
      // Get its current value
      var currentVal = parseInt($(input_middle).val(), 10);

      // Restrict value to given maximum
      var data_max = $(this).attr('data-max');
      if (data_max) {
        var maxVal = parseInt(data_max, 10);
      }

      // If is not undefined
      if (currentVal >= maxVal) {
        $(input_middle).val(currentVal);
      }
      else if (!isNaN(currentVal) ) {
        // Increment
        $(input_middle).val(currentVal + 1);
      }
      else {
        // Otherwise put a 1 there
        $(input_middle).val(1);
      }
      // $(this).prev().trigger('change');
      // Stimulus doesn't work with Jquery events
      trigger_event(fieldName, "change", true, false);
    });

    // This button will decrement the value till 0
    $(button_minus).click(function(e) {
      // Stop acting like a button
      e.preventDefault();
      // Get the field name
      var fieldName = $(this).attr('field');
      // Get its current value
      var currentVal = parseInt($(input_middle).val());
      // If it isn't undefined or its greater than 0
      var minVal = 4; // default - will be overridden later
      // Restrict value to given minimum
      var data_min = $(this).attr('data-min');
      var minVal = parseInt(data_min, 10);

      if (currentVal == minVal) {
        $(input_middle).val(currentVal);
      }
      else if (!isNaN(currentVal) && currentVal > 0.00) {
        // Decrement one
        $(input_middle).val(currentVal - 1);
      } else {
        // Otherwise put a 1 there
        $(input_middle).val(0.00);
      }
      // $(this).next().trigger('change');
      trigger_event(fieldName, "change", true, false);
    });
  }

}
