import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    console.log("CONNECT angle_drop_controller")

    var angle_drop = this.element
    var target_id  = angle_drop.dataset.targetId

    angle_drop.addEventListener("click", () => {
      $(target_id).stop().slideToggle();
      $(angle_drop).toggleClass('active');
    })
  }

}
