import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT button_back_controller")

    var button = this.element
    var url    = button.dataset.url

    button.addEventListener('click', () => { window.location.href = url })
  }

}
