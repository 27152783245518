import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log("CONNECT rail/abo_controller" )

    let element     = this.element
    let departure   = element.querySelector("#from")
    let destination = element.querySelector("#destination_name")
    let cabin_class = element.querySelector("#cabin_class")
    let dropdowns   = [...element.querySelectorAll("select.abo")]
    let params      = JSON.parse(element.dataset.aboParams)
    let url         = element.dataset.aboUrl

    const load = () => {
      let departure_ibnr   = departure.dataset.ibnr
      let destination_ibnr = destination.dataset.ibnr
      let needRecalc       = departure_ibnr.length && destination_ibnr.length;
      console.log(`rail/abo_controller: load? ${needRecalc ? "yes" : "no"}`)

      if (needRecalc) {
        let dataUrl = url + "&ibnr_departure=" + departure_ibnr + "&ibnr_arrival=" + destination_ibnr + "&cabin_class=" + cabin_class.value; 
        console.log("rail/abo_controller: fetch: " + dataUrl)
        fetch(dataUrl)
          .then(response => response.text())
          .then(html => {
            dropdowns.forEach((el, i) => {
              el.innerHTML = html

              let field_name = el.name.replace(/\[abo\]/, '');
              let abo        = params[field_name]?.abo

              if (abo) { $(el).val(abo) }
              else { el.selectedIndex = 0 }
              if (!$(el).val()) { el.selectedIndex = 0 } // if abo did not match any select options and hence was not set
            })
          })
      }
    }

    let observer = new MutationObserver(load)
    observer.observe(departure,   {attributes: true, attributeFilter: ['data-ibnr']})
    observer.observe(destination, {attributes: true, attributeFilter: ['data-ibnr']})
    cabin_class.addEventListener("change", load)

    setTimeout(load(), 1)

  }
}
