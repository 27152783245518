import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log("CONNECT cart/coupon_controller")

    // let element    = document.body.querySelector(".Coupon") // for testing from javascript console
    let element       = this.element
    let row           = element.querySelector(".coupon-data") 
    let cart_id       = row.dataset.cartId
    let url_update    = row.dataset.urlUpdate
    let url_delete    = row.dataset.urlDelete
    let input         = row.querySelector("#promocode")
    let button_update = row.querySelector("#redeem")
    let button_delete = row.querySelector("#delete") // optional

    button_update.addEventListener("click", () => {
      let url =  url_update + "?coupon_code=" + input.value;
      Rails.ajax({
        url: url,
        type: 'GET',
        dataType: 'script'
      });
    })

    if (button_delete !== null) {
      button_delete.addEventListener("click", () => {
        let url = url_delete;
        Rails.ajax({
          url: url,
          type: 'POST',
          dataType: 'script'
        });
      })
    }
  }

}
