import 'core-js'

// import * as Turbo from "@hotwired/turbo"
import { Turbo } from "@hotwired/turbo-rails"
global.Turbo = Turbo
console.log("IMPORT TURBO")

import jQuery from 'jquery'
/* evt Block wieder einkommentieren */

window.$ = jQuery
window.jquery = jQuery
global.$ = jQuery
global.jQuery = jQuery
global.jquery = jQuery


jQuery(function () {
  // console.log('Jquery works with Webpacker. Version: ' + jQuery.fn.jquery);
});

//import '../foundation/index'
import 'foundation' // loads '../foundation/index' ?

require('webpack-jquery-ui/autocomplete');
require('webpack-jquery-ui/controlgroup');
require('webpack-jquery-ui/datepicker');

import 'whatwg-fetch'

require('../src/javascripts/cart');
// import 'channels'

// import "controllers"
import Rails from 'rails-ujs'
Rails.start()
global.Rails = Rails

import "@stimulus/polyfills"

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { $ } from "jquery"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.consumer = consumer
StimulusReflex.initialize(application, { consumer })
if (process.env.RAILS_ENV === 'development') StimulusReflex.debug = true

// import a single image
// import './images/some_image.png';
// or if you have a big number of images, you can use require.context() to load all the files in a given folder
// require.context('images', true, /\.(svg|png|jpg)$/igm);
