import { Controller } from 'stimulus';

function hotel_init_rooms(max_rooms, val) {
    console.log("hotel init rooms")
    for(var i = 1; i <= max_rooms; i++) {
        if (i <= val) {
            $('#divroom_number' + i).show();
            fable(i, false);
        }
        else {
            $('#divroom_number' + i).hide();
            fable(i, true);
        }
    }
	/* already called by hotel_update_pax_age_selection()
	update_additional_pax_fields();
	*/
}

function fable(i,what){
	var r = '';

	if (i > 1) {
		r += '#room'+i+'_'
	}

    $(r+'adults').prop('disabled',what);
    $(r+'children').prop('disabled',what);
    for(var a=1; a<4; a++) {
		$('#room'+i+'_childage'+a).prop('disabled',what);
	}
}

/* will called by each number change click for adult/children */
function hotel_update_pax_age_selection(max_rooms, max_pax_per_room, max_children_per_room) {
	/* display / hide child dobs */

	for (var room_num = 1; room_num <= max_rooms; room_num++) {
		var room_id = 'room'+room_num+'_';

		var num_adults = parseInt($('#'+room_id+'adults' ).val());

		/* show adult age fields */
		for (var i = 1; i <= max_pax_per_room; i++) {
			var div = $('#'+room_id+'divadultage'+i);
			var selects = div.find('select');
			if (i <= num_adults) {
				div.show();
				selects.prop('disabled',false);
			}
			else {
				div.hide();
				selects.prop('disabled',true);
			}
		}

		var num_children = parseInt($('#'+room_id+'children').val());

		/* reset attribut data-max for this this room num_children selector */
		if (num_children > max_pax_per_room - num_adults) {
			num_children = max_pax_per_room - num_adults;
		}
		if (num_children > max_children_per_room) {
			num_children = max_children_per_room;
		}

		/* update num children */
		$('#'+room_id+'children').val(String(num_children));

		/* show child age fields */
		for (var i = 1; i <= max_children_per_room; i++) {
			var div = $('#'+room_id+'divchildage'+i);
			var selects = div.find('select');
			if (i <= num_children) {
				div.show();
				selects.prop('disabled',false);
			}
			else {
				div.hide();
				selects.prop('disabled',true);
			}
		}
	}
	/* fix naming */
	update_additional_pax_fields(max_rooms, max_pax_per_room, max_children_per_room);
}

function update_additional_pax_fields(max_rooms, max_pax_per_room, max_children_per_room) {
	var total_adults = 0
	var total_children = 0;
	var num_children = 1;
	var num_adults = 1;

	/* get total number of pax */
	for (var room_num = 1; room_num <= max_rooms; room_num++) {
		var room_id = 'room'+room_num+'_';

		var a = $('#'+room_id+'adults:visible');
		if (a.length) {
			total_adults += parseInt(a.val());
		}
		var c = $('#'+room_id+'children:visible');
		if (a.length) {
			total_children += parseInt(c.val());
		}
	}

	/* set numbers of passengers */
	$('input[name="children"][type="hidden"]').val(String(total_children));
	$('input[name="adults"][type="hidden"]').val(String(total_adults));

	for (var room_num = 1; room_num <= max_rooms; room_num++) {
		var room_id = 'room'+room_num+'_';

		/* set/fix adult age/abo select names  */
		for (var i = 1; i <= max_pax_per_room; i++) {
			var div = $('#'+room_id+'divadultage'+i);
			if (div) {
				var age = div.find('div.age select');
				var abo = div.find('div.abo select');
				if (div.is(':visible') && age && total_adults > 0) {
					age.attr("name", "passenger_AD_" + String(num_adults) + "[age]");
					abo.attr("name", "passenger_AD_" + String(num_adults) + "[abo]");
					total_adults--;
					num_adults++;
				} else {
					age.attr("name", "passenger_AD_OFF");
					abo.attr("name", "passenger_AD_OFF");
				}
			}
		}

		/* set/fix child age/abo select names  */
		for (var i = 1; i <= max_children_per_room; i++) {
			var div = $('#'+room_id+'divchildage'+i);
			if (div) {
				var age = div.find('div.age select');
				var abo = div.find('div.abo select');
				if (div.is(':visible') && age && total_children > 0) {
					age.attr("name", "passenger_CH_" + String(num_children) + "[age]");
					abo.attr("name", "passenger_CH_" + String(num_children) + "[abo]");
					total_children--;
					num_children++;
				} else {
					age.attr("name", "passenger_CH_OFF");
					abo.attr("name", "passenger_CH_OFF");
				}
			}
		}
	}
}

export default class extends Controller {

  connect() {
    console.log("CONNECT hotel/search_controller")

    const preselected_numberofrooms   = this.element.dataset.preselectedNumberofrooms;
    const max_pax_per_room_hotel      = this.element.dataset.maxPaxPerRoomHotel;
    const max_children_per_room_hotel = this.element.dataset.maxChildrenPerRoomHotel;
    let max_rooms                     = this.element.dataset.maxRooms;

    /* Fallback if max_rooms is not defined or set on html page */
    if (typeof max_rooms == "undefined") {
      max_rooms = 4
    }

    /*alert(max_rooms);*/

  	hotel_init_rooms(max_rooms, preselected_numberofrooms);
	  hotel_update_pax_age_selection(max_rooms, max_pax_per_room_hotel, max_children_per_room_hotel);

  	$(".qty.pax").on("change", function() {
  	  hotel_update_pax_age_selection(max_rooms, max_pax_per_room_hotel, max_children_per_room_hotel);
  	});

    $('.qty.rooms').change(function(e) {
        var val = parseInt($(this).val());
        hotel_init_rooms(max_rooms, val);
        hotel_update_pax_age_selection(max_rooms, max_pax_per_room_hotel, max_children_per_room_hotel);
    });

  }

}
