import { Controller } from 'stimulus'; 

const get_base_language       = (language_code, all_languages) => all_languages.filter(x => x.code === language_code)[0]?.base
const get_customer_name_short = (customer_id, all_customers)   => all_customers.filter(x => x.id   == customer_id)[0]?.name_short

export default class extends Controller {
  static targets = ["data", "code", "customer", "freePartOfCode", "language"]

  connect() {
    console.log("CONNECT admin/retailer_new_controller")
    this.updateCode()
  }

  updateCode() {
    var customer_name_short     = get_customer_name_short(this.customerTarget.value, JSON.parse(this.dataTarget.dataset.params).customers)
    var base_language           = get_base_language(this.languageTarget.value, JSON.parse(this.dataTarget.dataset.params).languages)
    this.codeTarget.textContent = customer_name_short + "_" + this.freePartOfCodeTarget.value + "_" + base_language
  }

}
