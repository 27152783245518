import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    const foundation_log = this.element.dataset.foundationLog
    console.log(`CONNECT foundation init controller: ${foundation_log}`)
    $(document).foundation();
    if (document.body.querySelector("[data-abide]") != null) {
      Foundation.reInit('abide');
      addAbideEventListeners()
    }
    // Hack due to a strange behaviour in FF. Without this the background images are not shown for deeplinks.
    // Doesn't work with querySelector btw. (It returns an empty String for style.backgroundImage)
    let css = $("#mini_basket_link a").css("background-image");
    $("#mini_basket_link a").css("background-image", css);

    css = $("#olt").css("background-image");
    $("#olt").css("background-image", css);
    // this.element.remove();
  }

}
