import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT scroll_to_top_auto_controller")

    $('html').scrollTop(0);
  }

}
