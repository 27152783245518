import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT scroll_to_top_controller")
    this.element.addEventListener('click', () => {$('html, body').animate({scrollTop: 0}, 1000);})
  }

}
