import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT admin/payment_provider_form_controller")

    const display_payment_provider_form_inputs = () => {
      switch(document.getElementById("payment_provider_name").value) {
        case "Olt":
          $("#payment_provider_form #toggle_display").hide();
          break;
        case "Wirecard":
          $("#payment_provider_form #toggle_display").show();
          $("#payment_provider_form #toggle_wirecard_display").show();
          break;
        case "Wirecard_WPPv2":
          $("#payment_provider_form #toggle_display").show();
          $("#payment_provider_form #toggle_wirecard_display").show();
          break;
        default:
          $("#payment_provider_form #toggle_display").show();
          $("#payment_provider_form #toggle_wirecard_display").hide();
      } 
    }

    const clear_payment_provider_form_inputs = () => {
      this.element.querySelector("#payment_provider_prepaid_account").checked                = false
      this.element.querySelector("#payment_provider_merchant_id").value                      = ""
      this.element.querySelector("#payment_provider_api_user_id").value                      = ""
      this.element.querySelector("#payment_provider_api_user_pw").value                      = ""
      this.element.querySelector("#payment_provider_signature_seed").value                   = ""
      this.element.querySelector("#payment_provider_wirecard_business_case_signature").value = ""
    }

    display_payment_provider_form_inputs();

    $("#payment_provider_form #payment_provider_name").on("input", function (e) {
      console.log("payment provider on input")
      clear_payment_provider_form_inputs();
      display_payment_provider_form_inputs();
    })

  }
}
