document.addEventListener("turbo:load", () => {
    const element = document.querySelector("#send-offer-mail");
    if (element) {
        element.addEventListener("ajax:error", () => {
            element.insertAdjacentHTML("beforeend", "<p>ERROR</p>");
            unblockCart();
        });
    }
});


