import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT mobile_filter_menu_controller")

    // var element      = document.body.querySelector(".choosebdy-content2 > div") // for testing from javscript console
    var element      = this.element
    var toggler      = element.querySelector("#selectbox-1")

    toggler.addEventListener("click", () => {
      if (toggler.classList.contains('active')) {
        toggler.classList.remove('active')
        var filter_block = element.querySelector("#filter-section-block") // reread every time from document, as filter might have been rerendered from server
        $(filter_block).stop().slideUp();
      } else {
        toggler.classList.add('active')
        var filter_block = element.querySelector("#filter-section-block")
        $(filter_block).stop().slideDown();
      }
    })
  }

}
