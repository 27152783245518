import { Controller } from "stimulus"

const load = (el) => {
  var url = el.dataset.url
  fetch(url)
    .then(response => response.text())
    .then(html => {
      el.innerHTML = html
      let abo = el.dataset.value

      if(abo) { $(el).val(abo) }
      else { el.selectedIndex = 0 }
      if(!$(el).val()) { el.selectedIndex = 0 } // if abo did not match any select options and hence was not set
    })
}


export default class extends Controller {

  connect() {
    console.log(`CONNECT cart/abo_controller (for faq ${this.element.id.split("_").slice(0)[0]} pax ${this.element.id.split("_").slice(-1)[0]})` )
    setTimeout(load(this.element), 1)
  }

}
