import { Controller } from 'stimulus'; 

/* takeover value from previous To Airport. Only for multistop */
function getPreviousTo(el) {
  console.log("get previous to")
	var airport_name = el.getAttribute("name");
	var a = airport_name.split("_");

	if (a.length == 2) {
		var airport_number = parseInt(a[1], 10);
		var last_airport = $("#to_" + (airport_number - 1));
		$("#" + airport_name).val(last_airport.val());
	}
}


export default class extends Controller {

  connect() {
    console.log("CONNECT flight/multi_stop_controller")

    var element = this.element
    var inputs  = [...element.querySelectorAll("input")]
    var froms   = inputs.filter(x => x.id.split("_")[0] == "from").slice(1) // all from-inputs except first

    froms.forEach(x => x.addEventListener("focus", () => getPreviousTo(x)))
  }

}
