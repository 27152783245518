// https://github.com/hotwired/turbo/issues/249

import { Controller } from 'stimulus';

// there is a bug within turbo that dosent let you navigate
// from a frame more than once, to fix this you have to reset the frame
// src and all works. this is a monkey patch. to use it
// place it directly on a frame, data-controller="turbo-frame"
// data-action="turbo:submit-end->turbo-frame#reset"

export default class extends Controller {
    reset() {
        this.element.src = "";
    }
}
                                                                            

