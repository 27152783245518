import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for RailpassReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
    // static targets = ["departure_date"]
    
    // connect() {
        // setTimeout(this.update_offer(), 200)
    // }

    update_offer(event) {
        if(event){event.preventDefault()}
        this.stimulate('Railpass#update_offer')
    }
    
    toggle_confirm(event) {
        if(event){event.preventDefault()}
        this.stimulate('Railpass#toggle_confirm')
    }
    
    /*
    reflexSuccess (element, reflex, error) {
        if(this.data.has("refresh")) {
            this.data.set("refresh", Time.now)       
        }
        // $("#_departure_date").datepicker();
    }
    */
    
    /*
    get availform() {
        if(this.hasAvailformTarget) {
          return $(this.availformTarget).serialize()
        }
        return ""
    }
    */
    
 
    
  /* Reflex specific lifecycle methods.
   * Use methods similar to this example to handle lifecycle concerns for a specific Reflex method.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Example:
   *
   *   <a href="#" data-reflex="RailpassReflex#example">Example</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "RailpassReflex#example"
   *
   *   error - error message from the server
   */

  // beforeUpdate(element, reflex) {
  //  element.innerText = 'Updating...'
  // }

  // updateSuccess(element, reflex) {
  //   element.innerText = 'Updated Successfully.'
  // }

  // updateError(element, reflex, error) {
  //   console.error('updateError', error);
  //   element.innerText = 'Update Failed!'
  // }
}
