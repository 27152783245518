import { Controller } from 'stimulus'; 

export default class extends Controller {
  static targets = ["data", "code", "freePartOfCode"]

  connect() {
    console.log("CONNECT admin/retailer_copy_to_child_controller")
  }

  updateCode() {
    var customer_name_short     = JSON.parse(this.dataTarget.dataset.params).customer_name_short
    var base_language           = JSON.parse(this.dataTarget.dataset.params).base_language
    this.codeTarget.textContent = customer_name_short + "_" + this.freePartOfCodeTarget.value + "_" + base_language
  }

}
