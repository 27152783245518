import { Controller } from 'stimulus'; 

const get_base_language = (language_code, all_languages) => all_languages.filter(x => x.code === language_code)[0]?.base

export default class extends Controller {
  static targets = ["data", "code", "freePartOfCode", "language"]

  connect() {
    console.log("CONNECT admin/retailer_copy_to_parent_controller")
  }

  updateCode() {
    var customer_name_short     = JSON.parse(this.dataTarget.dataset.params).customer_name_short
    var base_language           = get_base_language(this.languageTarget.value, JSON.parse(this.dataTarget.dataset.params).languages)
    this.codeTarget.textContent = customer_name_short + "_" + this.freePartOfCodeTarget.value + "_" + base_language
  }

}
