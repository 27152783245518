import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT avail_item_click_controller")

    var item   = this.element
    const url  = item.querySelector('.DetailLink').href
    var button = item.querySelector('.bottom-buttons .button')

    item.addEventListener('click', () => { if (!$(button).is(':visible')) { window.location.href = url; } })
  }

}
