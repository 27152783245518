import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {

    console.log("CONNECT admin/prepack_type_controller")

    var prepack_type             = this.element.querySelector("#prepack-type")
    var button_specific_prepack  = this.element.querySelector("#button-specific-prepack")
    var button_unlimited_prepack = this.element.querySelector("#button-unlimited-prepack")
    var specific_prepack         = this.element.querySelector("#specific-prepack")
    var unlimited_prepack        = this.element.querySelector("#unlimited-prepack")

    switch(prepack_type.value) {
      case "specific":
        specific_prepack.style.display  = "block"
        unlimited_prepack.style.display = "none"
        button_specific_prepack.classList.add('active')
        button_unlimited_prepack.classList.remove('active')
        break;
      case "unlimited":
        specific_prepack.style.display  = "none"
        unlimited_prepack.style.display = "block"
        button_specific_prepack.classList.remove('active')
        button_unlimited_prepack.classList.add('active')
        break;
      default:
        prepack_type.value              = "specific"
        specific_prepack.style.display  = "block"
        unlimited_prepack.style.display = "none"
        button_specific_prepack.classList.add('active')
        button_unlimited_prepack.classList.remove('active')
    } 

    button_specific_prepack.addEventListener("click", function(event){
      event.preventDefault()
      specific_prepack.style.display  = "block"
      unlimited_prepack.style.display = "none"
      button_specific_prepack.classList.add('active')
      button_unlimited_prepack.classList.remove('active')
      prepack_type.value = "specific"
    });

    button_unlimited_prepack.addEventListener("click", function(event) {
      event.preventDefault();
      specific_prepack.style.display  = "none"
      unlimited_prepack.style.display = "block"
      button_specific_prepack.classList.remove('active')
      button_unlimited_prepack.classList.add('active')
      prepack_type.value = "unlimited"
    })
  }

}
