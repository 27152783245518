import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT admin/partnerlink_index_controller")

    let element                      = this.element
    let partnerlink_selection_button = element.querySelector("#partnerlink_selection_button")
    let add_bookingnumber_button     = element.querySelector("#add_bookingnumber_partnerlink_button")
    let grid                         = element.querySelector("#partnerlink_grid")

    partnerlink_selection_button.addEventListener("click", () => {
      var id = $(grid).jqGrid('getGridParam', 'selrow');
      if (id) window.open('/partnerlink/' +id + '/selection');
      else alert ("Please select the record you want to show");
    });

    add_bookingnumber_button.addEventListener("click", () => {
      var id = $(grid).jqGrid('getGridParam', 'selrow');
      // if (id) window.open("/partnerlink/" +id + "/add_bookingnumber", "_blank", "toolbar=no,scrollbars=no,resizable=yes,top=500,left=500,width=500,height=400");
      if (id) window.open("/partnerlink/" +id + "/add_bookingnumber");
      else alert ("Please select the record you want to show");
    });

  }

}
