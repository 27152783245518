import { Controller } from 'stimulus'; 

var get_room_forms      = (el) => new Array(...el.querySelectorAll('.RoomOffer form'));
var get_transfer_inputs = (el) => new Array(...el.querySelectorAll("input"))

var get_selected_transfer = (el) => {
  var transfer = el.querySelector("input:checked");
  return { direction: transfer.dataset.direction, product_id: transfer.dataset.productId, option_id: transfer.dataset.optionId }
}

var add_transfer_to_room_form = (room_form, selected_transfer) => {
  var name_product_id  = selected_transfer.direction == "out" ? "transfer_out_product_id" : "transfer_home_product_id"
  var name_option_id   = selected_transfer.direction == "out" ? "transfer_out_option_id" : "transfer_home_option_id"
  var input_product_id = room_form.querySelector(`input[name=${name_product_id}]`)
  var input_option_id  = room_form.querySelector(`input[name=${name_option_id}`)

  if (input_product_id == null) { // initial
    var input_product_id   = document.createElement("input")
    input_product_id.type  = "hidden"
    input_product_id.name  = name_product_id
    input_product_id.value = selected_transfer.product_id
    room_form.appendChild(input_product_id)
  } else { // subsequent
    input_product_id.value = selected_transfer.product_id
  }

  if (input_option_id == null) { // initial
    var input_option_id   = document.createElement("input")
    input_option_id.type  = "hidden"
    input_option_id.name  = name_option_id 
    input_option_id.value = selected_transfer.option_id
    room_form.appendChild(input_option_id)
  } else { // subsequent
    input_option_id.value = selected_transfer.option_id
  }

}


var add_transfer_to_room_forms = (el) => {
  var selected_transfer = get_selected_transfer(el)
  var room_forms        = get_room_forms(document.body);
  room_forms.forEach(room_form => {add_transfer_to_room_form(room_form, selected_transfer)})
}

export default class extends Controller {

  connect() {
    console.log("CONNECT hotel/detail_transfers_controller")

    var transfer_inputs   = get_transfer_inputs(this.element)
    var selected_transfer = get_selected_transfer(this.element)
    var room_forms        = get_room_forms(document.body);

    room_forms.forEach(room_form => {add_transfer_to_room_form(room_form, selected_transfer)})
    transfer_inputs.forEach(x => {x.addEventListener("change", () => add_transfer_to_room_forms(this.element))})
  }

}
