// force fetching value from autocomplete list for deeplink values
// Must only be called for the first request

export function force_autocomplete_search() {
  var is_prepack = document.body.querySelector(".prepack");
  if(is_prepack) {
    return;
  }

  $(".ui-autocomplete-input").focus(function() {
    let $this = $(this);
    //if($this.is(':visible') && $this.attr("name") != "from" && $this.attr("name") != "to") {
    if($this.is(':visible')) {
      var val = $this.attr("value");
      if(val) {
        try {
          $this.autocomplete("search", val);
        } catch (err) {
          console.log(err);
        }

      }
    }
  });
  $(".ui-autocomplete-input").first().focus();
}
