import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT flight/search_passengers_controller")

    let adults   = this.element.querySelector("#adults")
    let children = this.element.querySelector("#children")
    let infants  = this.element.querySelector("#infants")

    // maximum 9 passengers
    adults.addEventListener("change",   (event) => click_minus_button_if_too_many_passengers(event.target));
    children.addEventListener("change", (event) => click_minus_button_if_too_many_passengers(event.target));
    infants.addEventListener("change",  (event) => click_minus_button_if_too_many_passengers(event.target));

    // not more infants than adults
    $(infants).next().click(() => setInfantsToAdultsValue());
    $(adults).prev().click(() => setInfantsToAdultsValue());

    function click_minus_button_if_too_many_passengers(el) {
      const max    = 9;
      const amount = parseInt(adults.value) + parseInt(children.value) + parseInt(infants.value);
      if (amount > max) $(el).prev().trigger("click");
    }

    function setInfantsToAdultsValue() {
      if ($(infants).val()+1 > $(adults).val()) {
        $(infants).val($(adults).val()-1);
      }
    }

  }

}
