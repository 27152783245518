import { Controller } from 'stimulus'; 

export default class extends Controller {
	static targets = ["image"]

  connect() {
    console.log("CONNECT admin/field_image_controller")
  }

  showPreview(e) {
    // https://dev.to/ibn_abubakre/styling-file-inputs-like-a-boss-mhm
    const [file] = e.target.files;
    var reader   = new FileReader();
    var image    = this.imageTarget;

    reader.onload = function(e)  {
      image.src = e.target.result;
    }

    reader.readAsDataURL(file);
  }

}
