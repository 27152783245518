import { Controller } from 'stimulus';


// call rail_controller action reset to remove filters from the session. Then reload the filter frame
async function resetAndReloadFilters(frame, reset_url) {
  console.log(`reset_url: ${reset_url}`)
  const response = await fetch(reset_url)
  console.log(`status: ${response.status}`)
  frame.reload()
}

export default class extends Controller {
  static values = {
    tripsPath: String,
    resetPath: String
  }

  connect() {
    console.log("CONNECT rail_filter_controller");
    this.element[this.identifier] = this
    console.log(this.identifier)
  }

  apply(event) {
    console.log("ACTION applyFilters");
    const filter_data = $("form#filters").serialize()
    const trips_url = this.tripsPathValue + "&" + filter_data + "&sort=" + this.sortByVal()
    this.reloadTrips(trips_url)
  }

  reset(event) {
    const reset_url = this.resetPathValue
    const frame = document.getElementById("rail_filters")
    resetAndReloadFilters(frame, reset_url)
    const trips_url = this.tripsPathValue + "&earlier=0&later=0&" + this.sortByVal()
    this.reloadTrips(trips_url)
  }

  reloadTrips(url) {
    const frame = document.getElementById("rail_trips");
    console.log(url);
    frame.src = url;
    frame.reload();
  }

  sortByVal() {
    return document.querySelector('#page-state > input[name=sort_by]').value
  }

}

