/*************************************************************************************************
    Extensions to foundation abide
    Online Travel AG SMA
    07.08.2015
    Author: Online Travel AG: SMA
*************************************************************************************************/
;(function ($, window, document, undefined) {
    'use strict';
    // add a date validator for german date format
    Foundation.Abide.defaults.patterns.dateDE = /^\d{1,2}[\.]\d{1,2}[\.]\d{4}$/;
    // add a date validator for airport format
    Foundation.Abide.defaults.patterns.airport = /^.+\s\/[A-Z]{3}$/;
    Foundation.Abide.defaults.patterns.station = /^.+, [A-Z]{2}$/;
	Foundation.Abide.defaults.patterns.hoteldest = /^\s*[A-ZÄÖÜ][^,]+, .*$/;
    // Foundation.Abide.defaults.patterns.airport = /^[a-zA-ZäöüÄÖÜ()\s\/]+$/;
    Foundation.Abide.defaults.patterns.names = /^[^0-9,\+\*$@=#%\/\[\]\{\}\\]{1,}$/;
    Foundation.Abide.defaults.patterns.phone = /^[(]?[+]?[\s0-9()\/-]*\d{2,}[\s0-9()\/-]*\d{2,}[\s0-9()\/-]*\d{2,}\s*$/;
    Foundation.Abide.defaults.patterns.phone_lcc = /^\+[1-9]{2}[\s01-9\/-]{2,}[\s0-9()\/-]*\d{2,}[\s0-9()\/-]*\d{2,}\s*$/;
    Foundation.Abide.defaults.patterns.cumulus = /(^|^[0-9]{13})$/; // empty or 13 numbers

    Foundation.Abide.defaults.validators.between = function(el, required, parent) {
        var given = Number(el.value),
            min = Number(el.getAttribute('data-min')),
            max = Number(el.getAttribute('data-max')),
            valid = (given >= min && given <= max);

        return valid;
    };


    Foundation.Abide.defaults.validators['olt_nighttrain_minstay'] = function(
        $el,      /* jQuery element to validate */
        required, /* is the element required according to the `[required]` attribute */
        parent    /* parent of the jQuery element `$el` */
     ) {
        // $('#form-error').text('');
        var ret = true;
        var night_trains_only = false;
        var el = document.getElementById("night_train_selection_out_night_trains_only");
        if(el && el.checked) {
            night_trains_only = true;
        }
		//alert(night_trains_only);
        if(night_trains_only) {
            var start_date = document.getElementById("departure_date");
            if(start_date) {start_date = new Date(start_date.value);}
            var return_date  = document.getElementById("return_date")
            if(return_date) {return_date = new Date(return_date.value);}

            if(start_date && return_date) {
                var datediff = (return_date - start_date)/86400000;
            }

			//alert(start_date && datediff)
            if(start_date && datediff < 2) {
                //$('#rail-searchform-error').text("Die Minimaldauer für Nachtzugreisen beträgt 2 Tage");
                ret = false;
            }
        }
        //alert(ret);
        return ret;
    };


    Foundation.Abide.defaults.validators.oltFlightNumPax = function(el, required, parent) {
        var msg = '';
        /*
        $("input.qty.pax").each(function(index) {
            numpax += parseInt($(this).val(), 10);
        });
        */

        var num_adults = parseInt($("input#adults").val() || "0", 10);
        var num_children = parseInt($("input#children").val() || "0", 10);
        var num_infants = parseInt($("input#infants").val() || "0", 10);
        var num_youths = parseInt($("input#youths").val() || "0", 10);
        var num_students = parseInt($("input#students").val() || "0", 10);

        if (num_adults == 0 && num_youths == 0 && num_students == 0) {
            msg = Msg.CHOOSE_PAX;
            $("input#adults").focus();
	    }
	    else if (num_adults != 0 && (num_youths != 0 || num_students != 0)) {
		    msg = Msg.INVALID_PAX_COMBINATION;
		    $("input#adults").focus();
		}
	    else if (num_youths != 0 && num_students != 0) {
		    msg = Msg.INVALID_YOUTH_STUDENT_COMBINATION;
            $("input#students").focus();
	    }
	    else if ((num_adults + num_youths + num_students) < num_infants) {
		    msg = Msg.TO_MANY_INFANTS;
            $("input#infants").focus();
	    }

        if(msg != '') {
            $('small.error[data-pax_error]').text(msg);
            return (false);
        }
        return(true);
    };

    Foundation.Abide.defaults.validators.railpass_youth_age = function($el, required, parent) {
        var id_day = $el.attr('data-railpass_youth_age_day');
        var id_mon = $el.attr('data-railpass_youth_age_mon');
        var id_year = $el.attr('data-railpass_youth_age_year');
         // dob date
        var day = parseInt($('#'+id_day).val(), 10);
        var mon = parseInt($('#'+id_mon).val(), 10);
        var year = parseInt($('#'+id_year).val(), 10);

        $el.closest(".Traveller").find("select").each((i, elem) => {
            const $elem = $(elem);
            $elem.removeClass("is-invalid-input");
        });

        var errordisplay = '#' + $el.attr('data-railpass_youth_age_error');
        $(errordisplay).hide();
        $(errordisplay).removeClass("form-error");

        if(day && mon && year) {
            var mindate = new Date(year + 16, mon - 1 , day);
            var maxdate = new Date(year + 25, mon - 1, day);
            // var checkdate = new Date(); // Calc age from dob and checkdate. We take today as checkdate at the moment. Maybe it changes to departure date or return date
            var checkdate = new Date($el.attr('data-railpass_start_date'));
            if(checkdate <  mindate) {
                $(errordisplay).addClass("form-error");
                $(errordisplay).show();
                return(false);
            }
            if(checkdate > maxdate) {
                $(errordisplay).addClass("form-error");
                $(errordisplay).show();
                return(false);
            }
        }
        return(true);
    };
    Foundation.Abide.defaults.validators.num_of_pax = function(
	  $el,      /* jQuery element to validate */
	  required, /* is the element required according to the `[required]` attribute */
	  parent    /* parent of the jQuery element `$el` */
	) {
		var ret = false;

		if (required) {
      let sum = 0;
      let i = $('input[name=max_pax]')
      let max_pax = 9;

      if (i.length) {
        max_pax = parseInt(i.val())
      }

			$('input.pax:visible').each(function() {
				sum += parseInt($(this).val());
			});
			ret = (sum <= max_pax);
		} else {
			ret = true;
		}

		/* alert('check called: '+ret); */
		return(ret);
	};

    Foundation.Abide.defaults.validators.place_selection = function($el, required, parent) {
		/* $el,      jQuery element to validate */
	    /* required, is the element required according to the `[required]` attribute */
	    /* parent    parent of the jQuery element `$el` */
		var ret = false;

		if (required) {
			var value    = $el.val();
			var selected = $el.attr('data-selected');

			if (value.length > 0 && value == selected) {
				ret = true;
			}
		} else {
			ret = true;
		}

		/* alert('check called: '+ret); */
		return(ret);
	};

}(jQuery, window, window.document));
