/* Stimulus Dropdown Controller
* Trigger an update for the options of a dropdown (output), when changing the value of an other dropdown (input)
* Author: Stefan Amann
* Date: 02.11.2020
*/
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "output" ]

  connect() {
    if(this.input) this.updateOutput()
  }

  updateOutput() {
    if(this.dataUrl()) {
      fetch(this.dataUrl())
      .then(response => response.text())
      .then(html => {
        this.outputTargets.forEach((el, i) => {
            el.innerHTML = html
        })
      })
    }
  }

  dataUrl() {
    return this.data.get("url").replace("input_id", this.input)
  }

  get input() {
    if(this.hasInputTarget) {
      return this.inputTarget.value
    }
    return ""
  }


}
