import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT tripadvisor_window_controller")

    // var element = document.body.querySelector('a[target="anzeigeFenster"]')
    var link = this.element

    link.addEventListener('click', () => {
      window_new = open("", link.target,"width=800,height=900,scrollbars=yes");
      window.window_new.focus();
      return true;
    })

  }

}
