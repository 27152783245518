import { Controller } from 'stimulus'; 
import 'slick-carousel';

export default class extends Controller {

  connect() {
    console.log("CONNECT image_slider_controller")

    $(this.element).slick({
      dots: false,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      adaptiveHeight: false
    });

    this.element.classList.remove("initially-hidden")
  }

}
