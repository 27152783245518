import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT angle_drop_railpass_controller")

    var element = this.element

    element.addEventListener('click', () => {
      $(element).toggleClass("active");
      $(element).find('i').toggleClass('fa fa-caret-right');
      $(element).find('i').toggleClass('fa fa-caret-down');
      $(element).parent().children('.toggle-content').slideToggle();
    })

  }

}
