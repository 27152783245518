import { Controller } from 'stimulus';
import { force_autocomplete_search } from './../../src/javascripts/autocomplete_utils.js';

/* OLT-autocomplete
* Jquery based autocomplete with json
* Author: Joachim Winkler
*/
function init_hotel_autocomplete(autocomplete_id, client, language, system, filter) {
  /* set defaults if not set */
  if (client == null) client = "";
  if (language == null) language = "";
  if (system == null) system = "";
  if (filter == null) filter = "";

  $(autocomplete_id).autocomplete({
    source: function( request, response ) {
        var url_param = "/hotel/destination"
        url_param += "?language="+language
        url_param += "&vendors="+system
        url_param += "&client="+client
        url_param += "&filter="+filter
        url_param += "&name="+request.term

      Rails.ajax({
        url: url_param,
        type: 'GET',
        success: function(data) {
            var d = data.documentElement.innerHTML;
            response($.map($(d).children('li'), function(item) {
			    var i = $(item)
				var c = $(i.children('span').detach())
				return {
					label: i.text() + ', ' + c.text(),
					value: i.attr('code'),
					value2: function() {
						var x = i.attr('iata');
						var y = i.attr('airport');
						return((y && y.length)?y:x);
					},
					value3: i.attr('ibnr')
				}
			}));
        }
      });
    },
    minLength: 2,
    autoFocus: true,
    select: function(event, ui) {
      $(autocomplete_id).val(ui.item.label);
	  $(autocomplete_id).attr('data-selected', ui.item.label);
	  $(this).attr("data-ibnr", ui.item.value3);// for stimulus
      $("#destination_oltid").val(ui.item.value);
      $("#destination_iata").val(ui.item.value2);
      $("#destination_ibnr").val(ui.item.value3);
      return false;
    },
    focus: function (event, ui) {

      var ARROWUP = 38;
      var ARROWDOWN = 40;

      if (event.keyCode == ARROWUP || event.keyCode == ARROWDOWN) {
        event.preventDefault();
        $(".ui-menu-item div").css("font-weight", "normal");
        $("div:contains('" + ui.item.label + "'):first").css("font-weight", "bold");
      }
      return false;
    }/*,
    open: function(event, ui) {
      if ($("#destination_oltid").val() != "") {
        $("#destination_oltid").val("");
        $("#destination_iata").val("");
        $("#destination_ibnr").val("");
      }
      return false;
    }*/
  });
}

export default class extends Controller {

  connect() {
    console.log("CONNECT hotel/autocomplete_controller")

    var element         = this.element
    var autocomplete_id = element.dataset.autocompleteId
    var client          = element.dataset.client
    var base_language   = element.dataset.baseLanguage
    var system          = element.dataset.system
    var filter          = element.dataset.filter

    init_hotel_autocomplete(autocomplete_id, client, base_language, system, filter)
    force_autocomplete_search()
  }

}
