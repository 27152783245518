import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT extra/search_passengers_controller")

    let adults   = this.element.querySelector("#adults")
    let children = this.element.querySelector("#children")
    let ages     = new Array(...this.element.querySelectorAll(".extra_ch_row"))

    show_or_hide_ages(parseInt(children.value));

  	adults.addEventListener("change",   (event) => handlePassengerChange(event.target));
  	children.addEventListener("change", (event) => handlePassengerChange(event.target));

    function handlePassengerChange(el) {
      show_or_hide_ages(parseInt(children.value));
      click_minus_button_if_too_many_passengers(el);
    }

    function click_minus_button_if_too_many_passengers(el) {
      const max    = 9;
      const amount = parseInt(children.value) + parseInt(adults.value);
      if (amount > max) $(el).prev().trigger("click");
    }

    function show_or_hide_ages(num_children) {
      ages.forEach((age, i) => {
        age.style.display = ((i + 1) <= num_children) ? "block" : "none";
      })
    }
  }

}
