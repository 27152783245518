import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    const foundation_log = this.element.dataset.foundationLog
    console.log(`CONNECT foundation reinit controller: ${foundation_log}`)
    $(document).foundation();
    if (document.body.querySelector("[data-abide]") != null) {
      Foundation.reInit('abide');
      addAbideEventListeners()
    }
  }

}
