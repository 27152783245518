import { Controller } from 'stimulus'; 

var next_id = (links) => {
  if (links.length > 0) {
    var ids_strings = Array.from(links).map(x => x.children[0].name.split(/\[|\]/)[1])
    var ids         = ids_strings.map(x => parseInt(x))
    return Math.max(...ids) + 1
  } else {
    return 0
  }
}

var show_inputs_for_one_link = (element, input_array_name, preselected_url, preselected_text) => {
	var links = element.children
	var id    = next_id(links)

	var div = document.createElement("div")
  div.classList.add("field-link")
	element.appendChild(div)

	var input_url = document.createElement("input")
  input_url.classList.add("field-link-url")
	input_url.setAttribute("type", "text")
	input_url.setAttribute("name", input_array_name + "[" + id + "][url]")
	input_url.setAttribute("placeholder", "url")
  input_url.value = preselected_url
	div.appendChild(input_url)

	var input_text = document.createElement("input")
  input_text.classList.add("field-link-text")
	input_text.setAttribute("type", "text")
	input_text.setAttribute("name", input_array_name + "[" + id + "][text]")
	input_text.setAttribute("placeholder", "text")
  input_text.value = preselected_text
	div.appendChild(input_text)

	var button_remove = document.createElement("i");
  button_remove.classList.add("field-link-remove")
  button_remove.classList.add("fa")
  button_remove.classList.add("fa-minus-circle")
	button_remove.addEventListener("click", e => {
    e.preventDefault();
    element = e.target.parentNode
    element.parentNode.removeChild(element)
})

	div.appendChild(button_remove);
}


var remove_link = (e) => {
  e.preventDefault()
  console.log(e.target)
  e.target.remove()
}


export default class extends Controller {
  static targets = ["data", "links"]

  connect() {
    console.log("CONNECT admin/fields_links_controller")
    this.data().links.forEach(x => {
      show_inputs_for_one_link(this.linksTarget, this.data().input_array_name, x.url, x.text)
    })
  }

  data() {
    return JSON.parse(this.dataTarget.dataset.params)
  }

  addNewLink(e) {
    e.preventDefault()
    show_inputs_for_one_link(this.linksTarget, this.data().input_array_name, "", "")
  }

}
