import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    tripsPath: String,
  }

  connect() {
    console.log("CONNECT rail_connections_controller");
  }

  earlier(event) {
    console.log("ACTION earlier");
    const trips_url = this.tripsPathValue + "&earlier=1&later=0" + "&sort=" + this.sortByVal()
    this.reloadTrips(trips_url)

  }

  later(event) {
    console.log("ACTION later");
    const trips_url = this.tripsPathValue + "&earlier=0&later=1" + "&sort=" + this.sortByVal()
    this.reloadTrips(trips_url)
  }

  reloadTrips(url) {
    const frame = document.getElementById("rail_trips");
    console.log(url);
    frame.src = url;
    frame.reload();
  }

  sortByVal() {
    return document.querySelector('#page-state > input[name=sort_by]').value
  }

}

