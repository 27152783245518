import { Controller } from 'stimulus'; 
import { Datepicker } from 'vanillajs-datepicker'

var new_datepicker = (elem) => {
  elem.value = null

  var datepicker = new Datepicker(elem, {
    format: "yyyy-mm-dd",
    maxNumberOfDates: 10,
    minDate: elem.dataset.earliestDepartureDate,
    maxDate: elem.dataset.latestDepartureDate
  });

  datepicker.setDate(elem.dataset.preselected.split(",").map(x => Number(x)), {clear: true, render: true});
}

export default class extends Controller {

  connect() {
    console.log("CONNECT admin/prepack_datepicker_controller")
    new_datepicker(this.element)
  }

}

