import { Controller } from 'stimulus'; 

// var el = this.element
// var el = document.body.querySelector(".default-design-form");

var getColorInputs = (el) => {
  return new Array(...el.querySelectorAll(".field-css-color input[type=color]"));
}

var getKeyNameColorInputs = (el) => {
  return new Array(...el.querySelectorAll(".field-css-key-name-color select"));
}

var readColor = (key, colorInputs) => {
  var keyColorPairs = colorInputs.map((x) => {return {key: x.name.split(/\[|\]/)[1], value: x.value}});
  var color         = keyColorPairs.filter(x => x.key == key)[0]?.value
  return color
}

var to_snakecase = (x) => x.toLowerCase().replace(/ /g,"_")

var preview = (keyNameColorInput, colorInputs) => {  
  var key                     = to_snakecase(keyNameColorInput.value)
  var color                   = readColor(key, colorInputs)
  var el_preview              = keyNameColorInput.parentNode.querySelector(".field-css-key-name-color-preview")
  el_preview.style.background = color
}

var previews = (keyNameColorInputs, colorInputs) => {
  keyNameColorInputs.forEach(x => preview(x, colorInputs))
}

export default class extends Controller {

  connect() {
    console.log("CONNECT admin/default_design_form_controller")
		var colorInputs        = getColorInputs(this.element)
		var keyNameColorInputs = getKeyNameColorInputs(this.element)
		previews(keyNameColorInputs, colorInputs)
		colorInputs.forEach(x => {x.addEventListener("change", () => previews(keyNameColorInputs, colorInputs))})
		keyNameColorInputs.forEach(x => {x.addEventListener("change", () => previews(keyNameColorInputs, colorInputs))})
	}

}




