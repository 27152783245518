import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT scroll_to_element_controller")

    let element   = this.element
    let target_id = element.dataset.targetId

    element.addEventListener("click", () => $('html, body').animate({scrollTop: $(target_id).offset().top}, 1000));
  }

}
