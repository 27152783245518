import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { clickTarget: String }

  connect() {
    console.log("CONNECT menu_sort_controller")

    var page_state     = document.body.querySelector("#page-state > input[name=sort_by]")
    var menu           = document.body.querySelector("#menu-sort")
    var menu_items     = [...menu.querySelectorAll("li.li-sort")]
    var dots           = menu.querySelector(".li-dots")
    var dropdown       = dots.querySelector(".dropdown")
    var dropdown_items = [...dropdown.querySelectorAll("li")]
    var all_items      = menu_items.concat(dropdown_items)

    const open_dropdown   = () => { dropdown.classList.add("open") }
    const close_dropdown  = () => { dropdown.classList.remove("open") }
    const toggle_dropdown = () => { dropdown.classList.contains("open") ? dropdown.classList.remove("open") : dropdown.classList.add("open") }

    const write_page_state = (val) => { page_state.value = val;}
    const colorize         = (val) => { all_items.forEach(x => x.dataset.value == val ? x.classList.add("selected") : x.classList.remove("selected")) }
    // const colorize         = (val) => { all_items.forEach(x.classList.toggle("selected")) }

    const initial_order = menu_items.map(item => item.dataset.value);

    const reorder = (items, val) => {
      if (val.length) {
        var items_reordered = []
        var wanted_order    = initial_order.filter(x => x == val).concat(initial_order.filter(x => x != val))
        wanted_order.forEach(val => {
          items_reordered.push(items.find(item => item.dataset.value == val))
        })
        return items_reordered
      } else {
        return items
      }
    }

    const reorder_and_attach = (val) => {
      reorder(menu_items, val).reverse().forEach(item => {menu.prepend(item);});
      reorder(dropdown_items, val).forEach(item => {dropdown.append(item);})
    }


    const handle_selection = (event) => {
      const val = event.target.dataset.value
      write_page_state(val);
      reorder_and_attach(val);
      colorize(val);
      console.log("CALL sort")
      this.sort(event);
    }

    reorder_and_attach(page_state.value)
    colorize(page_state.value);
    dots.addEventListener("click",      toggle_dropdown);
    dots.addEventListener("mouseleave", close_dropdown);
    all_items.forEach(x => x.addEventListener("click", handle_selection));
  }

  sort(event) {
    if(this.hasClickTargetValue) {
      const clickTarget = this.clickTargetValue
      const element = document.body.querySelector(clickTarget)
      element.click();
    }
  }

}
