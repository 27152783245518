import { Controller } from 'stimulus'; 

const zeroPad = (num, places) => String(num).padStart(places, '0');

const convertDecimalToTime = (time) => {
  const splits = time.split(".");
  const hour   = zeroPad(parseInt(splits[0]), 2);
  const minute = splits.length > 1 ? zeroPad(parseInt(splits[1])/10*60, 2) : "00";
  return hour + ":" + minute;
}


export default class extends Controller {

  connect() {
    console.log("CONNECT filter_time_slider_controller")

    $(".time-slider .slider").on("moved.zf.slider", function(){
      const min = $(this).find(".slider-handle:first").attr("aria-valuenow");
      const max = $(this).find(".slider-handle:last").attr("aria-valuenow");
      $(this).parent().next().find("input").first().val(convertDecimalToTime(min));
      $(this).parent().next().find("input").last().val(convertDecimalToTime(max));
    });
  }

}
