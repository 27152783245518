import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    const filter = this.element.dataset.filter

    console.log(`CONNECT filter_toggle_controller (${filter})`)


    this.element.addEventListener('click', () => {
      if ($("#filter-" + filter + " h2 i").hasClass("fa-caret-down")) {
        $("#filter-" + filter + " h2 i").stop().removeClass("fa-caret-down");
        $("#filter-" + filter + " h2 i").stop().addClass("fa-caret-up");
      } else {
        $("#filter-" + filter + " h2 i").stop().removeClass("fa-caret-up");
        $("#filter-" + filter + " h2 i").stop().addClass("fa-caret-down");
      }
      $("#filter-" + filter + " .filter-toggle").stop().slideToggle();
    })

  }

}
