import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT show_loading_and_redirect_controller")

    const url = this.element.dataset.url

    $('#loading').show();
    window.location.href = url
  }

}
