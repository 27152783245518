import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT admin/phrases_filter_checkboxes_controller")

    // let element = document.body.querySelector("#select-columns") // for testing from console
    let element       = this.element
    let opener        = this.element.querySelector("#open_column_selector")
    let box           = this.element.querySelector("#column_selector")
    let checkbox_all  = this.element.querySelector("#cb_select_all")
    let checkboxes    = [...this.element.querySelectorAll("input[type='checkbox']")]
    let apply_buttons = [...this.element.querySelectorAll(".apply")]
    let close_buttons = [...this.element.querySelectorAll(".close")]

    opener.addEventListener("click", () => $(box).show());
    close_buttons.forEach(x => x.addEventListener("click", () => $(box).hide()))
    apply_buttons.forEach(x => x.addEventListener("click", () => document.frm_column_selector.submit()))

    checkbox_all.addEventListener("change", () => {
      checkboxes.forEach(x => {x.checked = checkbox_all.checked})
    })

  }

}
