import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let trips_frame = document.querySelector("#rail_trips")
    let filters_frame = document.querySelector("#rail_filters")

    console.log("CONNECT rail_trips_controller")

    trips_frame.loaded.then(() => {
      filters_frame.disabled = false
      filters_frame.loading = "eager"
    })

  }
}