import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT filter_checkboxes_controller")

    // var element = document.querySelector("#filter-poi") // for testing from javascript console (example: hotel avail)
    var element        = this.element
    var checkbox_all   = element.querySelector(".checkbox_input_all input")
    var checkboxes     = [...element.querySelectorAll(".checkbox_input input")]
    var togglers       = [...element.querySelectorAll(".hide_toggler")]
    var toggable_spans = [...element.querySelectorAll(".hide_toggle")]

    if (checkbox_all !== null) {
      checkbox_all.addEventListener("click", () => {
        const wanted_state = checkbox_all.checked
        checkboxes.forEach(c => {c.checked = wanted_state;})
      })

      checkboxes.forEach(c => c.addEventListener("click", () => {
        const all_are_checked = checkboxes.map(x => x.checked).every(x => x === true) 
        checkbox_all.checked  = all_are_checked
      }))
    }

    togglers.forEach(t => t.addEventListener("click", () => {
      togglers.forEach(x => $(x).toggle());
      toggable_spans.forEach(x => $(x).toggle());
    }))
  }

}
