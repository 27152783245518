import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT map_visibility_controller")

    var element = document.body.querySelector("#TotalHotelMapDetail")
    var button_open = document.body.querySelector(".HotelMapOpen")
    var button_close = document.body.querySelector(".HotelMapClose")
    if (button_open != null) {
      button_open.addEventListener("click", () => {
        $(element).show(); 
        if (element.map != null) {
          element.map.update(); 
        }
      })
    }
    if (button_close != null) {
      button_close.addEventListener("click", () => $(element).hide())
    }
  }
}
