import { Controller } from 'stimulus';
import { delegate } from 'jquery-events-to-dom-events';

delegate('moved.zf.slider', ['event'])

export default class extends Controller {

  static targets = [
    'min',
    'minHidden',
    'max',
    'maxHidden',
    'slider'
  ];

  /* Remember camel case here and kebab case in html i.e. data-cets--booking-cets-url-value */
  static values = {
    exchangeRate: Number,
    priceOffset: Number,
    minAbs: Number,
    maxAbs: Number
  };

  connect() {
    console.log("filter_slider_price_controller#connect")
  }

  update(event) {
    var max = Number(this.maxTarget.value);
    var min = Number(this.minTarget.value);

    this.minHiddenTarget.value = String(Math.floor(min/this.exchangeRateValue - this.priceOffsetValue));
    this.maxHiddenTarget.value = String(Math.ceil(max/this.exchangeRateValue - this.priceOffsetValue));
    /*
    console.log("filter_slider_price#update "
      + "min(hidden): " + this.minTarget.value + "(" + this.minHiddenTarget.value + "), "
      + "max(hidden): " + this.maxTarget.value + "(" + this.maxHiddenTarget.value + ")"
    );
    */
  }

  update_max(event) {
    var max = Number(this.maxTarget.value);
    var min = Number(this.minTarget.value);

    if (max > min && max <= this.maxAbsValue) {
      this.maxHiddenTarget.value = String(Math.ceil(max/this.exchangeRateValue - this.priceOffsetValue));
      /*
      console.log("filter_slider_price#update_max "
        + "max(hidden): " + this.maxTarget.value + "(" + this.maxHiddenTarget.value + ")"
      );
      */
      $(this.sliderTarget).foundation('_reflow');
    }
  }

  update_min(event) {
    var max = Number(this.maxTarget.value);
    var min = Number(this.minTarget.value);

    if (min < max && min >= this.minAbsValue) {
      this.minHiddenTarget.value = String(Math.ceil(min/this.exchangeRateValue - this.priceOffsetValue));
      /*
      console.log("filter_slider_price#update_min "
        + "min(hidden): " + this.minTarget.value + "(" + this.minHiddenTarget.value + ")"
      );
      */
      $(this.sliderTarget).foundation('_reflow');
    }
  }

}
