import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT hotel/star_rating_controller")

    var element       = this.element;
    const num_stars   = parseInt(element.dataset.num_stars)
    var head          = element.querySelector(".head")
    var options_block = element.querySelector(".options-block")
    var checkbox_x    = options_block.querySelector("input[value='X']")

    head.addEventListener('click', () => $(options_block).toggle(100))

    document.addEventListener('click', (event) => {
      const clicked_outside = !event.composedPath().includes(element)
      if (clicked_outside) {$(options_block).hide(100)} 
    })

    const show_hide_stars = () => {
      if (checkbox_x.checked) {
        element.classList.add('all-active-stars');
        for (let z = 0; z < num_stars+1; z++) {
          var v = String(z)
          element.classList.remove('active-stars-' + v);
        }
      } else {
        element.classList.remove('all-active-stars');
        for (let z = 0; z < num_stars+1; z++) {
          var v = String(z)
          if ($(options_block).find('input[value=' + v + ']:checked').length) {
            element.classList.add('active-stars-' + v);
          } else {
            element.classList.remove('active-stars-'+ v);
          }
        }
      }
    }

    show_hide_stars();
    options_block.addEventListener('change', show_hide_stars)
  }
}
