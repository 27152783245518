// https://boringrails.com/articles/better-stimulus-controllers/

import { Controller } from 'stimulus'; 

const combinations = [
  {input_type: "boolean",          can_have_possible_values: false, available_validations: ["present"]                    },
  {input_type: "integer",          can_have_possible_values: true,  available_validations: ["present", "zero or greater"] },
  {input_type: "string",           can_have_possible_values: true,  available_validations: ["present"]                    },
  {input_type: "multiple strings", can_have_possible_values: true,  available_validations: ["present", "no duplicates"]   },
  {input_type: "email",            can_have_possible_values: false, available_validations: ["present"]                    },
  {input_type: "multiple emails",  can_have_possible_values: false, available_validations: ["present", "no duplicates"]   },
  {input_type: "url",              can_have_possible_values: false, available_validations: ["present"]                    },
  {input_type: "multiple urls",    can_have_possible_values: false, available_validations: ["present", "no duplicates"]   },
]

const available_validations    = (input_type) => combinations.filter(x => x.input_type === input_type)[0]["available_validations"]
const can_have_possible_values = (input_type) => combinations.filter(x => x.input_type === input_type)[0]["can_have_possible_values"]


export default class extends Controller {
  static targets = ["inputType"]

  connect() {
    console.log("CONNECT admin/default_cfg_form_controller")
    var input_type = this.inputTypeTarget.value
    this.hidePossibleValues()
    this.hideValidations()
    this.showPossibleValues(input_type)
    this.showValidations(input_type)
  }

  displayFieldsForChosenInputType(event) {
    var input_type = event.target.value
    this.clearPossibleValues()
    this.clearValidations()
    this.hidePossibleValues()
    this.hideValidations()
    this.showPossibleValues(input_type)
    this.showValidations(input_type)
  }



  get possibleValues() {
    return this.element.querySelector('input[name="default_cfg[possible_values]"]')
  }

  clearPossibleValues() {
    this.possibleValues.value = ""
  }

  hidePossibleValues() {
    this.possibleValues.parentNode.style.display = "none"
  }

  showPossibleValues(input_type) {
    if (can_have_possible_values(input_type)) {
      this.possibleValues.parentNode.style.display = "grid"
    }
  }

  
  get validations() {
    return new Array(...this.element.querySelectorAll('input[name="default_cfg[validations][]"')); 
  }

  clearValidations() {
    this.validations.forEach((el) => {
      const validation = el;
      validation.checked = false;
    });
  }

  hideValidations() {
    this.validations.forEach((el) => {
      const validation = el;
      validation.parentNode.style.display = "none"
    });
  }

  showValidations(input_type) {
    this.validations.forEach((el) => {
      const validation = el;
      if (available_validations(input_type).includes(validation.value)) {
        validation.parentNode.style.display = "grid"
      }
      if (input_type == "boolean") {
        this.validations.filter(validation => {
          if (validation.value == "present") {
            validation.checked = true
          }
        })
      }
    })
  }

}

