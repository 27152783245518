import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];
  static values  = {
    openText: String,
    closeText: String
  }
  static classes  = [ "open", "closed" ]

  initialize() {
    // this.open = false
  }

  off_contentTargetConnected() {
    console.log("CONNECT toggle target")
    this.contentTargets.forEach(function(target) {
        $(target).stop().toggle()
    })
  }

  toggle() {
    let content_id = event.params["contentid"];
    event.target.classList.toggle("active")
    $(`#${content_id}`).stop().slideToggle(200)
  }

  toggle_btn() {
    const content_id = event.params["contentid"];
    const button_id  = event.params["buttonid"];
    const icon_id    = event.params["iconid"];
    const status     = event.currentTarget.dataset.toggleStatus

    let button_el    = $(`#${button_id}`)
    let icon_el      = $(`#${icon_id}`)

    $(`#${content_id}`).stop().slideToggle(500)

    if (status) {
      if(status == "closed") {
        button_el
          .slideToggle(200)
          .text(this.closeTextValue)
          .slideToggle(200)

        event.currentTarget.dataset.toggleStatus = "open"
      }
      else {
        button_el
          .slideToggle(200)
          .text(this.openTextValue)
          .slideToggle(200)

        event.currentTarget.dataset.toggleStatus = "closed"
      }
    }
    icon_el
      .toggleClass(this.closedClass)
      .slideToggle(200)
      .toggleClass(this.openClass)
      .slideToggle(200)
  }

}
