import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    console.log("CONNECT admin/sessionlogs_show_xml_controller")

    let element = this.element
    let button  = element.querySelector("#show_xml_button")
    let grid    = element.querySelector("#sessionlogs_grid")

    button.addEventListener("click", () => {
      var id         = $(grid).jqGrid('getGridParam', 'selrow');
      var cellUrl    = $(grid).jqGrid ('getCell', id, 'url');
      var cellAction = $(grid).jqGrid ('getCell', id, 'file_name');

      if (id && cellUrl) {
        window.open(cellUrl, cellAction, 'height=600, width=800, scrollbars=1');
        /* doesn't work anymore with new version of JQuery
        if (navigator.userAgent.indexOf("Chrome") > -1) {
            window.open(cellUrl, cellAction, 'height=600, width=800, scrollbars=1');
        }
        else {
            var $dialog = $('<div></div>')
              .html('<iframe style="border: 0px; " src="' + cellUrl + '" width="100%" height="100%"></iframe>')
              .dialog({
                autoOpen: false,
                modal: true,
                height: 625,
                width: 900,
                title: cellAction
              });
              $dialog.dialog('open');
          }
          */
      }
      else {
        alert("Please select row first");
      }
    });
  }

}
