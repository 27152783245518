import { Controller } from 'stimulus';

function selectFare(angle_drop_id, target_id, is_night_train) {
  if(is_night_train && $(target_id).is(":hidden")) {
    $(target_id).stop().slideToggle();
    $(angle_drop_id).toggleClass('active');
  }
}

export default class extends Controller {

  connect() {
    console.log("CONNECT select_fare_controller")
    var element          = this.element
    const angle_drop_id  = element.dataset.angleDropId
    const target_id      = element.dataset.targetId
    const is_night_train = element.dataset.isNightTrain == "true"

    element.addEventListener("click", () => selectFare(angle_drop_id, target_id, is_night_train))
  }

}
