import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "show", "hide" ]

  connect() {
    console.log("CONNECT hotel/show_all_fares_controller")
      this.hideTargets.forEach(el => {
        el.hidden = true
      });
    }

    showAll(event) {
      this.hideTargets.forEach(el => {
        el.hidden = false
      });
      event.currentTarget.parentElement.hidden = true
    }
}
