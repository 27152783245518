/***
 * Small stimulus handbook
 * https://stimulus.hotwired.dev/handbook/introduction
 */
import { Controller } from 'stimulus';

/***
 * To use jquery events in stimulus some work has to be done.
 * See the links below for details:
 * https://www.npmjs.com/package/jquery-events-to-dom-events
 *
 * Some docs with deeper explains the problem and solution.
 * They leeds to the link above.
 * https://discuss.hotwired.dev/t/actions-on-custom-events/672
 * https://discuss.hotwired.dev/t/listening-to-jquery-events-on-stimulus-actions-like-ajax-success/573
 */
import { delegate } from 'jquery-events-to-dom-events';

/***
 * To use these events in stimulus actions you have to prefix them with '$'.
 * Example:
 * data-action="$formvalid.zf.abide->cets--booking#valid"
 */
delegate('forminvalid.zf.abide', ['event'])
delegate('formvalid.zf.abide', ['event'])

/***
 * try to get the cets object if not there set null
 */
var cets = (function() {
  try {
    return(window.cetsObject);
  } catch (ex) {
    alert('window.cetsObject not found');
    return(null);
  }
  return(null);
})();

function send_cmir_to_cets(customer_url, form_data) {
  console.log('customer url: ' + customer_url);
  console.log('form data: ' + form_data);

  Rails.ajax({
    url: customer_url,
    type: 'POST',
    data: form_data,
    success: function(document) {
      var str = new XMLSerializer().serializeToString(document)
      //console.log(str);
      cets.returnBooking(str);
    },
    error: function(data) {
      error_show("Fehler beim Senden des Warenkorbs");
    }
  })

  return(true);
}

export default class extends Controller {
  /* Remember always use camel case here 'myForm' is data-cets--booking-target="myForm" in html */
  static targets = [
    'form',
    'buttonOption',
    'buttonFix',
    'buttonCets'
  ];
  /* Remember camel case here and kebab case in html i.e. data-cets--booking-cets-url-value */
  static values = {
    formValid: {type: Boolean, default: false},
    bookingMethod: {type: String, default: ''},
    customerUrl: String,
    showCetsButton: Boolean
  };

  connect() {
    if (cets != null && this.showCetsButtonValue == true) {
      // Show cets button and hide all others
      $(this.buttonOptionTarget).hide();
      $(this.buttonFixTarget).hide();
    } else {
      // Show book and option buttons and hide all others
      $(this.buttonCetsTarget).hide();
    }
  }

  keydown(event) {
    if (event.keyCode == 13) {
      event.preventDefault();

      if ($(this.buttonCetsTarget).is(':visible')) {
        $(this.buttonCetsTarget).trigger('click');
      } else {
        $(this.buttonFixTarget).trigger('click');
      }

      return(false);
    }
  }

  submit(event) {
    var ret = true;
    //event.preventDefault();
    //var ret = false;

    var msg = 'event submit, form valid: ' + this.formValidValue + ', booking method: ' + this.bookingMethodValue;
    console.log(msg);
    //alert(msg);

    if (this.formValidValue == true) {
      this.formValidValue = false; // disable for the next run!

      if (this.bookingMethodValue == 'cets') {
        event.preventDefault();
        send_cmir_to_cets(this.customerUrlValue, $(this.formTarget).serialize());
        ret = false;
      }
      this.bookingMethodValue == '' // customer need to click again!
    }

    return(ret);
  }

  valid(event) {
    this.formValidValue = true;
    //alert('event valid, form valid:' + this.formValidValue);
  }

  invalid(event) {
    this.formValidValue = false;
    //alert('event invalid, form valid:' + this.formValidValue);
  }

  fix(event) {
    this.bookingMethodValue = 'fix';
    //alert('event button:'+this.bookingMethodValue);
  }

  option(event) {
    this.bookingMethodValue = 'option';
    //alert('event button:'+this.bookingMethodValue);
  }

  cets(event) {
    this.bookingMethodValue = 'cets';
    //alert('event button:'+this.bookingMethodValue);
  }
}
