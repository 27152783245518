import { Controller } from 'stimulus';
import { force_autocomplete_search } from './../../src/javascripts/autocomplete_utils.js';

function init_rail_autocomplete(autocomplete_id, client, language, system, filter) {
  if(autocomplete_id) {
    var autocomplete_prefix = '#' + $(autocomplete_id).attr("id").replace(/_name/, '');
  }
  $(autocomplete_id).autocomplete({
    source: function( request, response ) {
      Rails.ajax({
        url: "/rail/stations?name=" + request.term + "&language=" + language + "&customer=" + client ,
        type: "get",
        dataType: "json",
        data: "",
        success: function(data) {
          var json = data;
          var rows = [];
          if (json.ROW != undefined) {
            if (Array.isArray(json.ROW)) {
              rows = json.ROW;
            } else {
              rows = new Array(json.ROW);
            }
          }

          response(rows.map(item => {
            var label = item.DESCRIPTION + ", " + item.ISO_CODE;
            return {
              label: label,
              value: item.IBNR,
              country_code: item.ISO_CODE // change to code as soon as service delivers
            };
          }));

        },
        error: function(e) {
          console.log("error init_rail_autocomplete:");
        }
      });
    },
    minLength: 2,
    delay: 200,
    autoFocus: true,

    select: function(event, ui) {
      var $this = $(this);
      //this.value = ui.item.label;
      $this.val(ui.item.label);
      $this.attr('data-selected', ui.item.label);

      // $('#oltid').val(ui.item.value);
      // $('#iata').val(ui.item.value2);
      $(autocomplete_id).val(ui.item.label);
      $(autocomplete_prefix + '_ibnr').val(ui.item.value);
      $(autocomplete_id).attr("data-ibnr", ui.item.value);// for stimulus
      $(autocomplete_id).attr("data-country_code", ui.item.country_code);

      return false;
    },
    focus: function (event, ui) {

      var ARROWUP = 38;
      var ARROWDOWN = 40;

      if (event.keyCode == ARROWUP || event.keyCode == ARROWDOWN) {
        event.preventDefault();
        $(".ui-menu-item div").css("font-weight", "normal");
        $("div:contains('" + ui.item.label + "'):first").css("font-weight", "bold");
      }

      return false;
    },
  });
}

export default class extends Controller {

  connect() {
    console.log("CONNECT rail/autocomplete_controller")

    var element         = this.element
    var autocomplete_id = element.dataset.autocompleteId
    var client          = element.dataset.client
    var base_language   = element.dataset.baseLanguage
    var system          = element.dataset.system
    var filter          = element.dataset.filter

    init_rail_autocomplete(autocomplete_id, client, base_language, system, filter)
    force_autocomplete_search()
  }

}
