import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT hotel/detail_show_all_room_offers_controller")

    // let element  = document.querySelector('[data-controller="hotel--detail-show-all-room-offers"]')
    let element         = this.element
    let room_offers     = element.querySelectorAll("div.RoomOffer")
    let div_with_button = element.querySelector('div.ShowAllRoomOffers')

    // button to show more room offers only exists if hotel actually has more rooms
    if (div_with_button != null) {
      let button          = div_with_button.querySelector('.ButtonShowAllRoomOffers')

      button.addEventListener('click', () => {
        $(room_offers).show()
        $(div_with_button).hide()
      });
    }

  }

}
