import { Controller } from 'stimulus';

function rail_update_age_abo_display(pax_type, pax_num, pax_max) {
  for (var i = 1; i <= pax_max ; i++) {
    var id  = pax_type + '_' + i;
    if (i <= pax_num) {
      $('#' + id).show();
    } else {
      $('#' + id).hide();
    }
  }
}

function max_pax_exceeded(field_name, field_value, max_pax) {
  if(field_name == "adults") {
    var num_adults   = field_value;
    var num_children = parseInt($(".qty.pax[name='children']").val(), 10);
  }
  else {
    var num_children = field_value;
    var num_adults   = parseInt($(".qty.pax[name='adults']").val(), 10);
  }
  if((num_adults + num_children) > max_pax) {
    return true;
  }
  return false;
}


export default class extends Controller {

  connect() {
    console.log("CONNECT rail/search_passengers_controller")

    const element                  = this.element;
    const num_adults               = element.dataset.numAdults;
    const num_children             = element.dataset.numChildren;
    const max_pax                  = element.dataset.maxPax;
    const max_adults               = element.dataset.maxAdults;
    const max_children             = element.dataset.maxChildren;
    const max_pax_exceeded_warning = element.dataset.maxPaxExceededWarning;

    rail_update_age_abo_display("AD", num_adults, max_adults);
    rail_update_age_abo_display("CH", num_children, max_children);

     $(".qty.pax").on("change", function() {
       var $this = $(this);
       var field_name = $this.attr('name');
       var field_value = parseInt($this.val());
       if(max_pax_exceeded(field_name, field_value, max_pax)) {
         alert(max_pax_exceeded_warning);
         $this.val(String(field_value - 1));
         return;
       }
       // pax_max is the maximum for this pax_type while max_pax ist the maximum for all pax maximum :)
       var pax_type = (field_name == "children") ? "CH" : "AD";
       var pax_max = (field_name == "children") ? max_children : max_adults;
       rail_update_age_abo_display(pax_type, field_value, pax_max);
     });

  }
}
