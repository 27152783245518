import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {
    console.log("CONNECT angle_drop_fast_controller")

    // let element = document.querySelector(".big-search-bar .arrow") // for testing from console on extra detail
    let element      = this.element
    const target_id  = element.dataset.targetId
    const up_arrow   = element.dataset.upArrow
    const down_arrow = element.dataset.downArrow

    element.addEventListener("click", () => {
      $(target_id).toggle();

      if (element.getAttribute("src") == up_arrow) {
        element.setAttribute("src", down_arrow);
      } else {
        element.setAttribute("src", up_arrow);
      }

    })
  }

}
